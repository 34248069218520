@import '../~@drawbotics/drylus-style-vars/dist/vars.css';
:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: #db4c60;
}
:local(.guest) {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
}
:local(.guestContainer) {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
}
:local(.guestPanel) {
  width: 100%;
  overflow: hidden;
  border-radius: 1px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.075);
  display: flex;
}
:local(.form) {
  width: 33%;
}
:local(.info) {
  width: 67%;
  position: relative;
}
:local(.intro) {
  color: rgba(97, 97, 97, 0.7);
  margin-bottom: 50px;
  margin-top: 10px;
}
:local(.background) {
  position: absolute;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
}
:local(.overlay) {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
}
:local(.content) {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 30px;
}
:local(.projectInfo) {
  margin-bottom: 40px;
}
:local(.reference) {
  text-transform: uppercase;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 1.1em;
  margin-bottom: 15px;
  color: #2cb7c6;
}
:local(.white) {
  color: #fff;
}
:local(.title) {
  font-size: 1.7em;
  color: #fff;
}
:local(.stats) {
  background: rgba(255, 255, 255, 0.88);
  border-radius: 3px;
  padding: 20px;
  display: inline-block;
}
:local(.statsTitle) {
  text-transform: uppercase;
  color: #2cb7c6;
  font-size: 1.1em;
  font-family: "brandon-grotesque", sans-serif;
  margin-bottom: 10px;
}
:local(.statsContent) {
  margin-bottom: 20px;
  color: rgba(97, 97, 97, 0.7);
  overflow: hidden;
  position: relative;
}
:local(.statsContent):last-child {
  margin-bottom: 0;
}
:local(.user) {
  margin: 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
:local(.user):last-child {
  margin-bottom: 0;
}
:local(.image) {
  width: 30px;
  border-radius: 50%;
}
:local(.about) {
  margin-top: 40px;
}
:local(.aboutContent) {
  font-size: 0.9em;
  line-height: 1.6em;
  color: #fff;
}
:local(.buttonContainer) {
  margin-top: 65px;
}
:local(.signInButton) {
  min-width: 100%;
  padding: 15px 30px;
  font-size: 1.2em;
}
:local(.notAvailable) {
  font-weight: 600;
  font-style: italic;
  color: rgba(97, 97, 97, 0.7);
}
:local(.formPlaceholder) {
  height: 340px;
}
