@import '../../../../styles/~@drawbotics/drylus-style-vars/dist/vars.css';
:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: #db4c60;
}
:local(.previewWrapper) {
  height: 100%;
  flex: 1;
  background: var(--studio-preview-bg);
}
:local(.downloadButton) {
  position: absolute;
  top: -1px;
  right: 48px;
  color: #3ebfc6;
  font-size: 2em;
}
:local(.downloadButton):hover {
  cursor: pointer;
}
