@import '../../../../styles/~@drawbotics/drylus-style-vars/dist/vars.css';
:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: #db4c60;
}
:local(.notification) {
  background: #ffe5bf;
  color: #ba6e00;
  padding: 10px 20px;
  margin: 20px 0;
  border: 1px solid #ffaf3a;
  font-family: 'brandon-grotesque', 'Open Sans', Arial, sans-serif;
  font-weight: 600;
  font-weight: 1.1em;
  text-align: center;
}
