@import '../../../../styles/~@drawbotics/drylus-style-vars/dist/vars.css';
:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: #db4c60;
}
:local(.option) {
  width: 100%;
  color: #9e9e9e;
  font-size: 15px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-align: right;
}
:local(.name) {
  flex: 1;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  margin-right: 10px;
  text-align: left;
}
:local(.price) {
  display: inline-block;
  margin-right: 40px;
  font-size: 0.9em;
  font-family: 'Open Sans', sans-serif;
}
:local(.layout) {
  width: 50%;
  display: inline-block;
  margin-bottom: 15px;
  padding-right: 60px;
}
