@import '../~@drawbotics/drylus-style-vars/dist/vars.css';
:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: #db4c60;
}
.Container {
  width: 100%;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
}
.Container--full-height {
  height: 100%;
}
.Container--small {
  max-width: 1000px;
  margin: 0 auto;
}
.Container--xsmall {
  max-width: 500px;
  margin: 0 auto;
}
.Container--no-bottom-padding {
  padding-bottom: 0;
}
.Container--no-top-padding {
  padding-top: 0;
}
.Container--no-padding {
  padding: 0;
}
@media only screen and (min-width: 1200px) {
  .Container {
    width: 1200px;
  }
  .Container--fluid {
    width: 100%;
  }
}
@media only screen and (max-width: 425px) {
  .Container {
    padding: 15px;
  }
}
.Navbar {
  width: 100%;
  height: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: (65px - 35px) / 2;
  color: rgba(97, 97, 97, 0.7);
}
.Navbar__container {
  width: 1200px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}
.Navbar__icon {
  height: 30px;
  margin-right: 20px;
}
.Navbar__icon img {
  height: 100%;
}
.Navbar__title {
  font-weight: 400;
  font-size: 1.6em;
  font-family: "Open Sans", sans-serif;
}
.Navbar__subtitle {
  margin-left: 15px;
  font-weight: 400;
  font-size: 1.1em;
  font-family: "Open Sans", sans-serif;
}
.Navbar__controls {
  flex: 1;
  text-align: right;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Navbar__spacer {
  margin: 0 (65px - 35px) / 2;
}
.Navbar__divisor {
  width: 1px;
  height: 35px;
  margin: 0 (65px - 35px) / 2;
  background: #eeeeee;
  display: inline-block;
}
.Navbar__link {
  transition: all 0.15s;
  color: rgba(97, 97, 97, 0.7);
  padding: 10px 15px;
  text-transform: uppercase;
  font-family: "brandon-grotesque", sans-serif;
  letter-spacing: 0.05em;
  font-weight: 600;
  font-size: 0.9em;
  display: inline-block;
}
.Navbar__link:hover {
  color: #616161;
  cursor: pointer;
}
.Navbar__text {
  color: rgba(97, 97, 97, 0.7);
  padding: 10px 15px;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0.05em;
  font-size: 0.9em;
  display: inline-block;
}
.Navbar--transparent {
  padding-top: (150px - 35px) / 2;
  padding-bottom: (150px - 35px) / 2;
  height: 150px;
  background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0));
  box-shadow: 0 0;
}
.Navbar--transparent-inverse {
  background: transparent;
}
.Navbar--transparent-inverse .Button {
  color: #fff;
  border-color: #fff;
}
.Navbar--transparent-inverse .Button:hover {
  background: #fff;
  color: rgba(97, 97, 97, 0.7);
}
.Navbar--transparent-inverse .Dropdown__trigger,
.Navbar--transparent-inverse .Navbar__link {
  color: #fff;
}
.Navbar--transparent-inverse .Dropdown__trigger:hover,
.Navbar--transparent-inverse .Navbar__link:hover {
  color: #eeeeee;
}
.Navbar--with-container {
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
}
.Navbar--inverse {
  background: #1e7d87;
  color: #fff;
}
.Navbar--inverse .Navbar__link,
.Navbar--inverse .Navbar__dropdown,
.Navbar--inverse .Navbar__text {
  color: #fff;
}
.Navbar--inverse .Navbar__link:hover,
.Navbar--inverse .Navbar__dropdown:hover,
.Navbar--inverse .Navbar__text:hover {
  color: #d9d9d9;
}
.Navbar--inverse .Navbar__link .Dropdown__trigger,
.Navbar--inverse .Navbar__dropdown .Dropdown__trigger,
.Navbar--inverse .Navbar__text .Dropdown__trigger {
  color: #fff;
}
.Navbar--inverse .Navbar__link .Dropdown__trigger:hover,
.Navbar--inverse .Navbar__dropdown .Dropdown__trigger:hover,
.Navbar--inverse .Navbar__text .Dropdown__trigger:hover {
  color: #d9d9d9;
}
.Navbar--inverse .Navbar__divisor {
  background: #fff;
  opacity: 0.2;
}
@media only screen and (max-width: 425px) {
  .Navbar__container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .Navbar--transparent {
    padding-top: 32.5px;
    padding-bottom: 32.5px;
    height: 100px;
  }
}
@media print {
  .Navbar {
    display: none;
  }
}
:local(.alerts) {
  position: fixed;
  bottom: 30px;
  left: 30px;
  z-index: 999;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
:local(.alert) {
  margin-top: 15px;
  height: auto;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  font-size: 0.9em;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;
  transition: transform 0.45s cubic-bezier(0.44, 0.11, 0.07, 1.15);
  transform: translateX(-200%);
}
:local(.visible) {
  composes: alert;
  transform: translateX(0);
}
:local(.category) {
  width: 54px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.15s;
}
:local(.content) {
  flex: 1;
  padding: 18px;
  color: rgba(97, 97, 97, 0.7);
  line-height: 1.6em;
  width: 300px;
}
:local(.error) {
  composes: category;
  background: #db4c60;
}
:local(.success) {
  composes: category;
  background: #48b762;
}
:local(.action) {
  padding: 18px;
  justify-content: flex-end;
  align-items: flex-start;
  color: rgba(97, 97, 97, 0.5);
  transition: all 0.15s;
}
:local(.action):hover {
  cursor: pointer;
  color: rgba(97, 97, 97, 0.7);
}
