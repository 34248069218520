@import '../../../../styles/~@drawbotics/drylus-style-vars/dist/vars.css';
:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: #db4c60;
}
:local(.studio) {
  height: 100%;
}
:local(.previewAndSidebar) {
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  padding: 15px;
}
:local(.fullScreen) {
  width: 100%;
  height: 100%;
}
:local(.viewerWithControls) {
  width: 100%;
  height: 100%;
  display: flex;
}
:local(.control) {
  width: 30px;
  flex-shrink: 0;
  background-color: var(--studio-preview-bg);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
:local(.disabled) {
  composes: control;
  color: #ccc;
}
:local(.leftControl) {
  composes: control;
  font-size: 1.4em;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
:local(.leftControl):hover {
  font-size: 1.6em;
}
:local(.rightControl) {
  composes: control;
  font-size: 1.4em;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
:local(.rightControl):hover {
  font-size: 1.6em;
}
