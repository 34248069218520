@import '../../../../styles/~@drawbotics/drylus-style-vars/dist/vars.css';
:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: #db4c60;
}
:local(.list) {
  margin-top: 60px;
}
:local(.section) {
  margin-top: 30px;
}
:local(.subtitle) {
  font-size: 1.3em;
  color: rgba(97, 97, 97, 0.7);
}
:local(.panelTitle) {
  font-size: 1.2em;
  color: #2cb7c6;
  margin-bottom: 30px;
}
:local(.services) {
  color: rgba(97, 97, 97, 0.7);
  margin-bottom: 20px;
}
:local(.icon) {
  font-size: 3.5em;
  text-align: center;
}
:local(.info) {
  text-transform: uppercase;
  margin-top: 5px;
}
:local(.reference) {
  composes: info;
  color: #2cb7c6;
}
:local(.actions) {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
:local(.action) {
  margin-left: 10px;
}
