@import '../../../../styles/~@drawbotics/drylus-style-vars/dist/vars.css';
:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: #db4c60;
}
:local(.section) {
  margin: 30px 0;
}
:local(.basicInfo) {
  font-size: 0.96em;
  color: #616161;
  margin-bottom: 30px;
}
:local(.orderItem) {
  margin: 15px 0;
}
:local(.itemType) {
  text-transform: uppercase;
  font-size: 1.2em;
  color: #2cb7c6;
}
:local(.total) {
  text-align: right;
  padding-right: 12px;
  font-size: 1.35em;
  text-transform: uppercase;
  color: #2cb7c6;
  font-weight: 400;
}
:local(.name) {
  color: #616161;
  font-weight: 600;
}
