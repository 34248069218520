@import '../~@drawbotics/drylus-style-vars/dist/vars.css';
:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: #db4c60;
}
:local(.outdatedBrowserBanner) {
  background: #db4c60;
  color: #fff;
  text-align: center;
  padding: 15px;
}
:local(.title) {
  text-transform: uppercase;
  font-weight: bold;
}
:local(.text) {
  font-size: 0.9em;
  margin-top: 5px;
  font-weight: 300;
}
