@import '../../../../styles/~@drawbotics/drylus-style-vars/dist/vars.css';
:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: #db4c60;
}
:local(.statusBar) {
  padding: 25px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
:local(.projectInfo) {
  display: flex;
  align-items: center;
}
:local(.serviceIcon) {
  width: 55px;
  height: 55px;
  margin-right: 20px;
  font-size: 55px;
  line-height: 51px;
  text-align: center;
  color: #2cb7c6;
}
:local(.serviceName) {
  color: #9e9e9e;
  text-transform: uppercase;
  font-size: 16px;
  margin-right: 10px;
  font-weight: 600;
}
:local(.projectRef) {
  font-weight: 600;
  font-size: 16px;
  color: #2cb7c6;
}
:local(.projectName) {
  color: #2cb7c6;
  margin: 0;
  font-size: 27px;
  font-weight: 600;
  letter-spacing: 0.05em;
  position: relative;
  top: 3px;
}
:local(.projectMembers) {
  text-align: right;
  margin-bottom: 15px;
}
:local(.projectMembers) > div {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  display: inline-block;
  border-radius: 100%;
  font-weight: 600;
  color: #fff;
  background: rgba(44, 183, 198, 0.5);
  margin-left: 10px;
}
:local(.projectStatus) {
  color: #9e9e9e;
  text-transform: uppercase;
  font-size: 15px;
}
