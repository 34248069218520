// ----------------------
// DRAFT SELECTOR
//
// Component file
// ----------------------


:local(.draftSelector) {
  background: transparent;
  border: 0;
  outline: none !important;
  margin-left: 5px;
  cursor: pointer;
}
