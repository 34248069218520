@import '../../../../styles/~@drawbotics/drylus-style-vars/dist/vars.css';
:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: #db4c60;
}
:local(.creditSliderContainer) {
  display: flex;
  align-items: center;
  position: relative;
}
:local(.creditSlider) {
  flex: 1;
  position: relative;
}
:local(.bonusSteps) {
  position: absolute;
  width: 100%;
}
:local(.creditsIndicator) {
  position: absolute;
  width: 100%;
  top: -30px;
}
:local(.step) {
  display: inline-block;
  text-align: center;
  position: absolute;
  margin-top: 15px;
  color: rgba(97, 97, 97, 0.7);
  max-width: 100px;
  transform: translateX(-50% - 3px);
}
:local(.step)::before {
  content: " ";
  width: 0;
  height: 0;
  border-style: solid;
  border-color: #2cb7c6 transparent transparent transparent;
  border-width: 10px 10px 0 10px;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}
:local(.step):last-of-type {
  transform: translateX(-65%);
}
:local(.step):last-of-type:after {
  transform: none;
}
:local(.indicator) {
  max-width: 150px;
  color: rgba(97, 97, 97, 0.7);
  transform: translateX(-50% - 3px);
  position: absolute;
  margin-bottom: 15px;
  text-align: center;
}
:local(.indicator):last-of-type {
  white-space: nowrap;
}
:local(.icon) {
  min-width: 100px;
  text-align: center;
  font-size: 50px;
  padding: 0 40px;
  color: #2cb7c6;
}
:local(.bonusIcons) {
  position: absolute;
  width: 100%;
  top: 50px;
}
:local(.iconBonus) {
  position: absolute;
  width: 140px;
  transform: translateX(-45%);
}
:local(.textContainer) {
  display: flex;
  justify-content: space-between;
}
:local(.text) {
  color: #fff;
  padding: 3px 8px;
  text-transform: uppercase;
  font-size: 0.9em;
  text-align: center;
}
:local(.title) {
  composes: text;
}
:local(.subtitle) {
  composes: text;
}
