@import '../../../../styles/~@drawbotics/drylus-style-vars/dist/vars.css';
:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: #db4c60;
}
:local(.section) {
  margin-bottom: 30px;
}
:local(.leftItem) {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  height: 100%;
  max-height: 200px;
}
:local(.rightItem) {
  flex: 3;
  display: flex;
}
:local(.itemTitle) {
  text-transform: uppercase;
  margin-top: 20px;
  color: rgba(97, 97, 97, 0.7);
  font-family: 'brandon-grotesque';
  font-weight: 600;
  font-size: 0.9em;
}
:local(.itemTitle):first-child {
  margin-top: 0;
}
:local(.group) {
  margin-bottom: 30px;
}
:local(.group):last-child {
  margin-bottom: 0;
}
:local(.createOrganization) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
:local(.spinner) {
  position: absolute;
  top: -5px;
  margin-left: 15px;
}
:local(.actionCell) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
