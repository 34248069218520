@import '../../../../styles/~@drawbotics/drylus-style-vars/dist/vars.css';
:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: #db4c60;
}
:local(.title) {
  text-align: center;
  font-weight: 400;
  margin-top: 60px;
}
:local(.sliderContainer) {
  margin-bottom: 120px;
  margin-top: 150px;
}
:local(.creditContainer) {
  display: flex;
}
@media only screen and (max-width: 425px) {
  :local(.creditContainer) {
    flex-direction: column;
  }
}
:local(.selectCredits) {
  flex: 1;
  height: 100%;
}
:local(.totalCredits) {
  width: 400px;
  margin-left: 30px;
}
@media only screen and (max-width: 425px) {
  :local(.totalCredits) {
    margin-left: 0;
    margin-top: 30px;
    width: 100%;
  }
}
:local(.button) {
  margin-top: 30px;
  width: 100%;
}
:local(.row) {
  padding: 15px 0;
}
:local(.row):first-child {
  padding-top: 0;
}
:local(.borderedRow) {
  composes: row;
  border-bottom: 1px solid #eeeeee;
}
:local(.total) {
  font-weight: 600;
  color: rgba(97, 97, 97, 0.7);
  margin-right: 6px;
}
:local(.subtitle) {
  color: rgba(97, 97, 97, 0.5);
}
:local(.credits) {
  font-weight: 600;
  text-align: right;
  color: #2cb7c6;
  font-size: 1.2em;
  float: right;
}
:local(.countContainer) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
:local(.label) {
  font-family: "brandon-grotesque", sans-serif;
  color: rgba(97, 97, 97, 0.7);
  text-transform: uppercase;
  font-weight: 600;
}
:local(.bonus) {
  color: rgba(97, 97, 97, 0.5);
  min-width: 170px;
  text-align: right;
}
:local(.noAddress) {
  color: #db4c60;
  text-align: center;
  font-size: 0.9em;
  margin-top: 15px;
  line-height: 1.5;
}
:local(.noAddress) a {
  text-decoration: underline;
}
