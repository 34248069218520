@import '~ittsu/src/styles/vars.less';
@import '~@drawbotics/drylus-style-vars/dist/vars.css';


@default-font-family: @base-font-family;
@alt-font-family: @base-font-family-alt;
@bouncy-animation-curve: cubic-bezier(0.44, 0.11, 0.07, 1.29);


:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: @brand-red;
}
