@import '../../../../styles/~@drawbotics/drylus-style-vars/dist/vars.css';
:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: #db4c60;
}
:local(.title) {
  margin: 0;
  margin-bottom: 30px;
  color: #2cb7c6;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 0.02em;
}
:local(.optionsContainer) {
  position: relative;
  /*padding: 20px 30px;*/
  padding: 20px 0;
  /*background: #fff;*/
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border-top: 1px solid #2cb7c6;
}
:local(.correctionRound) {
  width: 30%;
  color: #9e9e9e;
  padding: 20px 30px;
  padding-left: 0;
}
:local(.options) {
  width: 70%;
  display: inline-block;
  vertical-align: top;
}
:local(.approve) {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
:local(.credits) {
  color: #2cb7c6;
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}
:local(.price) {
  font-size: 1.5em;
  padding: 0 0 0 20px;
}
:local(.approveButton) {
  width: 400px;
  padding: 15px;
  text-transform: uppercase;
  background: #2cb7c6;
  color: #fff;
  font-size: 19px;
  font-weight: 600;
  border: 0;
  cursor: pointer;
}
:local(.approveButton)::after {
  text-transform: none;
  width: 90%;
  white-space: normal !important;
  font-size: 18px;
  line-height: 1.3em;
  font-weight: normal;
}
:local(.approveButton):disabled {
  background: rgba(44, 183, 198, 0.4);
}
:local(.approveButton):hover:enabled {
  background: #23909c;
}
