@import '../~@drawbotics/drylus-style-vars/dist/vars.css';
:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: #db4c60;
}
:local(.overlay) {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  overflow: hidden;
}
.modalTransition-enter {
  opacity: 0;
}
.modalTransition-enter [data-element="modal"] {
  transform: scale(1.3);
  opacity: 0;
}
.modalTransition-enter-active {
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.44, 0.11, 0.07, 1.29);
}
.modalTransition-enter-active [data-element="modal"] {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.44, 0.11, 0.07, 1.29);
}
.modalTransition-exit {
  opacity: 1;
}
.modalTransition-exit [data-element="modal"] {
  transform: scale(1);
  opacity: 1;
}
.modalTransition-exit-active {
  opacity: 0.01;
  transition: all 0.3s cubic-bezier(0.44, 0.11, 0.07, 1.29);
}
.modalTransition-exit-active [data-element="modal"] {
  transform: scale(1.2);
  opacity: 0.01;
  transition: all 0.3s cubic-bezier(0.44, 0.11, 0.07, 1.29);
}
