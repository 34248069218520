@import '../../../../styles/~@drawbotics/drylus-style-vars/dist/vars.css';
:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: #db4c60;
}
:local(.annotation) {
  width: 400px;
  position: absolute;
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  z-index: 999;
}
:local(.annotation)::before,
:local(.annotation)::after {
  width: 0;
  height: 0;
  content: " ";
  position: absolute;
}
:local(.confirmationOverlay) {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  color: #9e9e9e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
:local(.button) {
  text-transform: uppercase;
  border: 0;
  font-size: 14px;
  font-weight: bold;
  padding: 7px 25px;
  margin-right: 20px;
  cursor: pointer;
  background: #ededed;
}
:local(.button):hover {
  background: #e0e0e0;
}
:local(.deleteCancel) {
  composes: button;
}
:local(.deleteOk) {
  composes: button;
  background: #db4c60;
  color: #fff;
}
:local(.deleteOk):hover {
  background: #cb2940;
}
:local(.topLeft) {
  composes: annotation;
}
:local(.topLeft)::before {
  left: -12px;
  top: 7px;
  border-style: solid;
  border-width: 13px 16px 13px 0;
  border-color: transparent #fff transparent transparent;
}
:local(.topRight) {
  composes: annotation;
}
:local(.topRight)::after {
  right: -12px;
  top: 7px;
  border-style: solid;
  border-width: 13px 0 13px 16px;
  border-color: transparent transparent transparent #fff;
}
:local(.bottomLeft) {
  composes: annotation;
}
:local(.bottomLeft)::before {
  left: -12px;
  bottom: 7px;
  border-style: solid;
  border-width: 13px 16px 13px 0;
  border-color: transparent #fff transparent transparent;
}
:local(.bottomRight) {
  composes: annotation;
}
:local(.bottomRight)::after {
  right: -12px;
  bottom: 7px;
  border-style: solid;
  border-width: 13px 0 13px 16px;
  border-color: transparent transparent transparent #fff;
}
:local(.header) {
  height: 40px;
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
  padding: 0 10px;
  color: #9e9e9e;
}
:local(.tabsBar) {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
:local(.tabs) {
  height: 100%;
}
:local(.tab) {
  height: 100%;
  border-bottom: 2px solid #9e9e9e;
  display: inline-flex;
  align-items: flex-end;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0 15px;
  color: #e0e0e0;
  padding-bottom: 7px;
}
:local(.isActive) {
  composes: tab;
  font-weight: bold;
  color: #9e9e9e;
}
:local(.tabsBarAction) {
  cursor: pointer;
}
:local(.tabsBarAction):hover {
  color: #bdbdbd;
}
:local(.body) {
  height: calc(100% - 40px);
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
:local(.textarea) {
  width: 100%;
  height: 100%;
  border: 0;
  color: #757575;
  outline: none !important;
  flex: 1;
  resize: vertical;
  min-height: 60px;
}
:local(.actionBar) {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}
:local(.actionIcons) {
  width: 25px;
  overflow: hidden;
  color: #9e9e9e;
  cursor: pointer;
  position: relative;
}
:local(.actionIcons):hover .dbi {
  color: #bdbdbd;
}
:local(.actionIcons) input[type="file"] {
  width: 25px;
  opacity: 0;
  position: relative;
  z-index: 10;
  cursor: pointer;
}
:local(.cancelButton) {
  background: none;
  border: none;
  text-decoration: underline;
  color: #9e9e9e;
  padding: 7px 0;
}
:local(.saveButton) {
  composes: button;
  background: #2cb7c6;
  color: #fff;
}
:local(.saveButton):disabled {
  background: rgba(44, 183, 198, 0.4);
}
:local(.saveButton):hover:enabled {
  background: #23909c;
}
:local(.attachment) {
  width: 100%;
  line-height: 2em;
  position: relative;
}
:local(.progressBar) {
  height: 3px;
  background: #abdeb7;
  content: " ";
  position: absolute;
  bottom: 0;
  left: 0;
}
:local(.attachmentData) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
  color: #9e9e9e;
  font-size: 15px;
}
:local(.attachmentButton) {
  cursor: pointer;
  font-size: 0.8em;
}
:local(.problemSolver) {
  overflow: hidden;
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  margin-top: 15px;
  background-color: #fff;
  position: relative;
  z-index: 9999999999999;
}
:local(.problemHeader) {
  background-color: #42a5f5;
  color: #fff;
  white-space: pre-wrap;
  display: flex;
  align-items: stretch;
}
:local(.avatar) {
  display: inline-block;
  width: 20%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
:local(.avatar) img {
  max-width: 60px;
  max-height: 60px;
  border-radius: 100%;
}
:local(.description) {
  display: inline-block;
  max-height: 120px;
  overflow-y: auto;
  width: 80%;
  padding: 15px 15px 15px 0px;
}
:local(.problemAnswer) {
  padding: 15px;
}
:local(.problemAnswerInput) {
  min-height: 60px;
  width: 100%;
  resize: vertical;
  border: 0;
  outline: none;
  color: #616161;
}
:local(.problemActions) {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
}
:local(.problemActionsButton) {
  width: 48%;
}
