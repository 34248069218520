@import '../../../../styles/~@drawbotics/drylus-style-vars/dist/vars.css';
:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: #db4c60;
}
:local(.sidebar) {
  width: 320px;
  min-width: 320px;
  height: 100%;
  flex-basis: 320px;
  margin-left: 15px;
  position: relative;
  overflow: hidden;
}
:local(.scrollable) {
  height: calc(100% - 90px);
  overflow-y: auto;
}
:local(.validatePreview) {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
:local(.goBack) {
  width: 100%;
  text-decoration: underline;
  color: #9e9e9e;
  font-size: 16px;
  text-align: center;
  display: block;
  margin-bottom: 15px;
}
:local(.validateButton) {
  composes: approveButton from '!less-loader!./summary.less';
  width: 100%;
  font-size: 15px;
  padding: 10px;
}
:local(.approvedText) {
  width: 100%;
  font-size: 15px;
  padding: 10px;
  color: #2cb7c6;
  border: 1px solid #2cb7c6;
  outline: 0 !important;
  background: transparent;
  cursor: default;
  text-transform: uppercase;
  font-weight: bold;
}
