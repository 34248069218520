@import '../../../../styles/~@drawbotics/drylus-style-vars/dist/vars.css';
:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: #db4c60;
}
:local(.previews) {
  margin-bottom: 50px;
  margin-top: 25px;
}
:local(.preview) {
  width: 100%;
  height: 290px;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  border-bottom: 2px solid rgba(158, 158, 158, 0.3);
  background: #e0e0e0;
}
:local(.preview):hover {
  border-color: rgba(158, 158, 158, 0.8);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
:local(.preview):hover [data-element="hover-image"] {
  opacity: 1;
}
:local(.imageContainer) {
  height: calc(100% -  45px );
}
:local(.image) {
  height: 100%;
  background-size: cover;
  background-position: center;
}
:local(.hoverImage) {
  width: 100%;
  top: 0;
  right: 0;
  bottom: 45px;
  left: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
:local(.hoverButton) {
  padding: 7px 25px;
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}
:local(.validatedLayer) {
  composes: hoverImage;
  opacity: 1;
  background: rgba(72, 183, 98, 0.6);
}
:local(.problemLayer) {
  composes: hoverImage;
  opacity: 1;
  background: rgba(219, 76, 96, 0.6);
}
:local(.validatedLabel) {
  composes: hoverButton;
  border: 0;
  text-transform: none;
  font-size: 1.3em;
  font-weight: 300;
}
:local(.validatedLabel) > i {
  position: relative;
  top: 2px;
  margin-right: 10px;
}
:local(.info) {
  height: 45px;
  line-height: 44px;
  background: #fff;
  border-top: 1px solid rgba(16, 67, 73, 0.15);
  font-family: 'Open Sans', sans-serif;
  color: #9e9e9e;
  padding: 0 10px;
  font-size: 0.9em;
}
:local(.comments) {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}
:local(.commentsCount) {
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 100%;
  display: inline-block;
  background: #db4c60;
  color: #fff;
  font-weight: 600;
  text-align: center;
  margin-right: 5px;
}
:local(.filename) {
  width: 50%;
  display: inline-block;
  color: #9e9e9e;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
  text-align: right;
}
