img {
  max-width: 100%;
}


.Header {
  z-index: 1;
}

.Main {
  z-index: 0;

  &__content {
    background: var(--background-color);

    @media print {
      background: none;
    }
  }
}


input, textarea {
  appearance: none;
  -webkit-border-radius: 0px;
}


body {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-smoothing: antialiased !important;

  // Ittsu overrides
  font-size: 15px !important;
  line-height: 1.15 !important;
  letter-spacing: normal !important;
}
