// ----------------------
// LOADING CONTAINER
//
// Container file
// ----------------------


:local(.loadingContainer) {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


:local(.loadingContainerSmall) {
  composes: loadingContainer;
  width: auto;
  height: auto;
}
