@import '../~@drawbotics/drylus-style-vars/dist/vars.css';
:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: #db4c60;
}
:local(.recoverPassword) {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
}
:local(.container) {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
:local(.panel) {
  width: 600px;
  position: relative;
  top: -10%;
}
:local(.buttonContainer) {
  display: flex;
  justify-content: space-between;
}
:local(.goBackLink) {
  color: #9e9e9e;
  text-transform: uppercase;
  font-size: 0.9em;
}
:local(.goBackLink):hover {
  color: #757575;
}
