@import '../../../../styles/~@drawbotics/drylus-style-vars/dist/vars.css';
:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: #db4c60;
}
:local(.annotation) {
  position: relative;
  width: 100%;
  min-height: 100px;
  border: 1px solid #e0e0e0;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  display: flex;
  align-items: center;
  font-size: 17px;
  padding: 10px;
  overflow: hidden;
  cursor: pointer;
  opacity: 0.8;
}
:local(.annotation):last-child {
  border-bottom-width: 1px;
}
:local(.annotation):hover {
  opacity: 1;
  background: #fff;
}
:local(.isOpen) {
  composes: annotation;
  opacity: 1;
  background: #fff;
}
:local(.problemLabel) {
  height: 16px;
  width: 62px;
  position: absolute;
  right: 5px;
  top: 5px;
  color: #fff;
  font-size: 12px;
  border-radius: 3px;
  background-color: #db4c60;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
:local(.annotationIndex) {
  width: 26px;
  min-width: 26px;
  height: 26px;
  border-radius: 50%;
  line-height: 26px;
  text-align: center;
  background: #db4c60;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  margin-right: 15px;
  flex-basis: 26px;
}
:local(.annotationInfo) {
  width: calc(100% - 55px);
  display: inline-block;
}
:local(.annotationComment) {
  width: 100%;
  color: #757575;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 3px;
}
:local(.attachmentsList) {
  width: 80%;
  font-size: 0.8em;
  color: #9e9e9e;
  margin-top: 10px;
  padding-left: 10px;
  list-style: none;
}
:local(.attachmentsList) > li {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
:local(.annotationAuthor) {
  color: #2cb7c6;
  font-size: 0.9em;
  display: block;
}
:local(.annotationDate) {
  color: #9e9e9e;
  font-size: 0.9em;
  display: block;
}
