@import '../../../../styles/~@drawbotics/drylus-style-vars/dist/vars.css';
:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: #db4c60;
}
:local(.title) {
  font-size: 1.1em;
  color: rgba(97, 97, 97, 0.7);
  margin-bottom: 15px;
  font-weight: 600;
}
:local(.section) {
  margin-bottom: 30px;
}
:local(.section):last-child {
  margin-bottom: 0;
}
:local(.rightAlign) {
  composes: section;
  text-align: right;
}
:local(.vatInfo) {
  font-size: 0.7em;
  color: rgba(97, 97, 97, 0.5);
  padding-top: 5px;
}
:local(.noAddress) {
  color: rgba(97, 97, 97, 0.7);
  font-style: italic;
}
