@import '../~@drawbotics/drylus-style-vars/dist/vars.css';
:root {
  --studio-ui-bg: white;
  --studio-ui-text: #166682;
  --studio-preview-bg: #DAE4EC;
  --studio-sidebar-separator-color: #e0e0e0;
  --studio-dot-bg: #db4c60;
}
:local(.criticalError) {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
}
:local(.criticalContainer) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
:local(.backgroundImage) {
  height: 30vh;
  margin-bottom: 30px;
}
:local(.title) {
  font-size: 3em;
  margin-bottom: 15px;
}
:local(.subtitle) {
  font-size: 1.2em;
}
:local(.content) {
  text-align: center;
  margin-bottom: 60px;
  color: rgba(97, 97, 97, 0.7);
}
:local(.action) {
  color: #616161;
  font-size: 1.2em;
}
